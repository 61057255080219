@import "../../theme.scss";
$gap-menu: 100;

.navbar {
  position: relative;
  background: #fff;
  z-index: 9;

  border-bottom: 1px solid #e0e0e0;

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: #{$nav-height}px;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   bottom: 0;
    //   width: calc(100% - 30px);
    //   height: 2px;
    //   background-color: #606060;
    // }

    #show-menu {
      display: none;

      background-color: transparent;
      border: none;
      outline: none;
    }

    .menu {
      display: flex;
      gap: 50px;
      justify-content: space-between;
      align-items: center;

      #hide-menu {
        display: none;
      }

      li {
        position: relative;

        color: #000;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: calc((100px / 2) * -1);
          transform: translateY(-50%);
          width: 2px;
          height: 24px;

          background: #fff;
        }

        a:hover {
          color: #278ba2;
        }
      }
      li:last-child::after {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    .container {
      height: 66px;

      &::after {
        display: none;
      }

      .logo {
        max-width: 148px;
      }
      #show-menu {
        display: block;
      }

      .show {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 100%;
          width: 100%;
          height: calc(100vh - 225px);
          z-index: -1;
          background-color: #191c1abd;
        }
      }
      .menu {
        position: fixed;
        top: -100%;
        left: 0;
        z-index: 9;
        width: 100%;
        border-radius: 0 0 12px 12px;

        transition: all 0.5s ease;
        flex-direction: column;
        gap: 0px;
        padding: 20px 15px;

        background: #fff;

        #hide-menu {
          background-color: transparent;
          border: none;
          outline: none;
          display: block;

          position: absolute;
          right: 20px;
          top: 15px;
          z-index: 9;

          width: 18px;
          height: 18px;
        }
        li {
          width: 100%;
          padding: 9px 0;
          border-bottom: 1px solid #606060c2;

          &::after {
            display: none;
          }

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}
