.configuration-page {
  nav {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px;

    // background-color: #f1ffec;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #dadada;
    }
  }
  .step-main {
    min-height: calc(100vh - 99px - 81px);
    max-width: 907px;
    margin: 0 auto;
    padding: 33px 0 15px;

    display: flex;
    align-items: center;

    .h1-title {
      color: #000000;
      text-align: center;
      font-family: "Roboto";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      margin-bottom: 5px;
    }
    .h5-desc {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin-bottom: 20px;
    }
    .h3-desc {
      color: red;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      margin-bottom: 15px;

      b {
        color: #bd0000;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }
    }
    /////////////////
    .stepper-wrapper {
      max-width: 535px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      gap: 44px;
      flex-grow: 1;

      .stepper-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        gap: 7px;

        transition: all 0.5s ease;

        &.active {
          flex: 0 0 30%;
        }

        .step-name {
          color: #06d6a0;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        // &::before {
        //   position: absolute;
        //   content: "";
        //   border-bottom: 2px solid #ccc;
        //   width: 100%;
        //   top: 20px;
        //   left: -50%;
        //   z-index: 2;
        // }

        // &::after {
        //   position: absolute;
        //   content: "";
        //   border-bottom: 2px solid #ccc;
        //   width: 100%;
        //   top: 20px;
        //   left: 50%;
        //   z-index: 2;
        // }

        .step-icon {
          flex: 0 0 40px;

          filter: grayscale(100%);
        }

        &.active .step-icon {
          font-weight: bold;
          filter: unset !important;
        }

        &.active {
          .step-name {
            position: relative;
            color: #06d6a0 !important;
            border-bottom: 1px solid #06d6a0;
          }
        }

        &.completed {
          .step-icon {
            filter: unset;

            // ICON CHECKED
            // &:after {
            //   content: "";
            //   position: absolute;
            //   left: 0;
            //   top: 0;
            //   width: 100%;
            //   height: 100%;

            //   background: url(../../../assets/images/configuration/progress/checked.png)
            //     no-repeat center;
            //   transition: all 0.5s ease;

            //   animation: showIcon 0.5s ease;
            //   @keyframes showIcon {
            //     0% {
            //       opacity: 0;
            //     }
            //     100% {
            //       opacity: 1;
            //     }
            //   }
            // }
          }
          .step-name {
            color: #000;
          }
        }

        &.redirect {
          .step-icon {
            // &:after {
            //   content: "";
            //   position: absolute;
            //   left: 0;
            //   top: 0;
            //   width: 100%;
            //   height: 100%;

            //   background: url(../../../assets/images/configuration/progress/redirect.png)
            //     no-repeat center;
            //   transition: all 0.5s ease;

            //   animation: showIcon 0.5s ease;
            //   @keyframes showIcon {
            //     0% {
            //       opacity: 0;
            //     }
            //     100% {
            //       opacity: 1;
            //     }
            //   }
            // }
          }
        }

        // &.completed::after {
        //   position: absolute;
        //   content: "";
        //   border-bottom: 2px solid #4bb543;
        //   top: 20px;
        //   left: 50%;
        //   z-index: 3;
        //   transition: all 0.5s ease;

        //   animation: scaleStep 1s ease;

        //   @keyframes scaleStep {
        //     0% {
        //       width: 0;
        //     }
        //     100% {
        //       width: 100%;
        //     }
        //   }
        // }

        &:first-child::before {
          content: none;
        }
        &:last-child::after {
          content: none;
        }
      }
    }
    .title-progress-circular {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;

      margin-bottom: 69px;
    }

    p.desc-configuration {
      color: #000;
      text-align: justify;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    ///
    ///
    ///

    // .box-progress {
    //   max-width: 525px;
    //   margin: auto;

    //   display: flex;
    //   justify-content: space-between;

    //   .progress-item {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     text-align: center;

    //     text-align: center;
    //     font-family: Inter;
    //     font-size: 14px;
    //     font-style: normal;
    //     font-weight: 500;
    //     line-height: normal;

    //     filter: grayscale(100%);
    //     color: #a6a7a8;

    //     &.active {
    //       filter: unset;
    //       color: #000;
    //     }
    //     &.completed {

    //     }
    //   }
    // }
    .box-question-answer {
      border-radius: 12px;
      padding: 23px 27px 31px;
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
      border: 1px solid #06d6a0;

      margin-bottom: 24px;

      .question {
        margin-bottom: 15px;

        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .answer {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
          flex: 1;
          padding: 10px 20px;

          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;

          border-radius: 10px;
          background: #f5f5f5;
        }
        button.active {
          background: #06d6a0;
          color: #fff;
        }
      }
    }
  }
  footer {
    position: relative;
    padding: 32px 0;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #dadada;
    }

    p {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media (max-width: 768px) {
  .configuration-page {
    nav {
      padding: 14px;
      .logo {
        max-width: 148px;
      }
    }
    .step-main {
      .h1-title {
        font-size: 18px;

        margin-bottom: 5px;
      }
      p.desc-configuration {
        font-size: 12px;
      }
      .h3-desc {
        font-size: 14px;

        margin-bottom: 10px;

        b {
          color: #bd0000;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }
      }

      .stepper-wrapper {
        .stepper-item {
          .step-name {
            font-size: 10px;
          }
          .step-icon {
            max-width: 25px;
          }
        }
      }
    }
    footer {
      padding: 27px 0;
      p {
        font-size: 10px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .configuration-page .step-main {
    padding: 20px 0;
  }
  .configuration-page .box-main {
    margin-bottom: 20px;
  }
  .configuration-page .box-question-answer .answer button {
    font-size: 14px;
    padding: 8px 20px;
  }
  .configuration-page .step-main .box-question-answer {
    padding: 23px 15px 31px;
  }
  .configuration-page .step-main .box-question-answer .answer button {
    padding: 5px 20px;
  }
}
