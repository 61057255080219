.redirect-page {
  position: relative;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;

  .redirect-content {
    padding: 0 15px;
  }
  nav {
    width: 100%;
    padding: 17px;

    text-align: center;
    background-color: #4fb329;
  }

  h1 {
    color: #000;
    text-align: center;
    font-family: Days One;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 17px;
  }

  .configuration {
    color: #48c619;
    text-align: center;
    font-family: Inter;
    font-size: 26px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 17px;
  }

  .text {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 62px;
  }

  .logo-upstart {
    text-align: center;
    margin-bottom: 61px;
  }

  .loading-text h3 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  footer p {
    padding: 15px 0 34px;

    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span[class^="dot-"] {
    opacity: 0;
  }
  .dot-one {
    animation: dot-one 2s infinite linear;
  }
  .dot-two {
    animation: dot-two 2s infinite linear;
  }
  .dot-three {
    animation: dot-three 2s infinite linear;
  }
  @keyframes dot-one {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-two {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-three {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (max-width: 768px) {
  .redirect-page {
    nav {
      padding: 14px 0;
      .logo {
        max-width: 148px;
        margin: 0 auto;
      }
    }
    h1 {
      font-size: 18px;
      line-height: normal;

      margin-bottom: 10px;
    }

    .configuration {
      font-size: 18px;
      line-height: normal;

      margin-bottom: 11px;
    }
    .text {
      font-size: 14px;
      line-height: normal;

      margin-bottom: 26px;
    }
    .logo-upstart {
      max-width: 200px;
      margin: 0 auto 27px;
    }
    .loading-text h3 {
      font-size: 18px;
      line-height: normal;
    }
    footer p {
      padding: 21px 0;
      font-size: 12px;
      line-height: normal;
    }
  }
}
