.redirect-upstart {
  position: relative;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;

  nav {
    position: relative;

    width: 100%;
    padding: 17px;

    text-align: center;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #606060;
    }
  }

  .step-main {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 41px 0 52px;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #48c619;

    background-color: #fff;
  }
  h1 {
    color: #000;
    text-align: center;
    font-family: Days One;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 17px;
  }

  .configuration {
    color: #48c619;
    text-align: center;
    font-family: Inter;
    font-size: 26px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 17px;
  }

  .text {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .logo-upstart {
    text-align: center;
    margin-bottom: 61px;
  }

  .loading-text h3 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: 15px;
  }

  footer {
    width: 100%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #606060;
    }

    p {
      padding: 15px 0 34px;

      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  span[class^="dot-"] {
    opacity: 0;
  }
  .dot-one {
    animation: dot-one 2s infinite linear;
  }
  .dot-two {
    animation: dot-two 2s infinite linear;
  }
  .dot-three {
    animation: dot-three 2s infinite linear;
  }
  @keyframes dot-one {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-two {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot-three {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (max-width: 768px) {
  .redirect-upstart {
    nav {
      padding: 14px 0;
      .logo {
        max-width: 148px;
        margin: 0 auto;
      }
    }
    h1 {
      font-size: 18px;
      line-height: normal;

      margin-bottom: 10px;
    }
    .configuration {
      font-size: 18px;
      line-height: normal;

      margin-bottom: 11px;
    }
    .text {
      font-size: 14px;
      line-height: normal;

      margin-bottom: 0;
    }
    .logo-upstart {
      max-width: 174px;
      margin: 0 auto 57px;
    }
    .loading-text h3 {
      font-size: 18px;
      line-height: normal;
    }
    footer p {
      padding: 21px 0;
      font-size: 12px;
      line-height: normal;
    }
  }
}
