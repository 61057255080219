.email-contact {
  .step-main {
    // display: flex;
    justify-content: center;
    align-items: flex-start;

    min-height: calc(100vh - 100px - 68px - 314px);

    .step-form {
      width: 100%;
      padding: 41px 30px 52px;
      box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      background-color: #fff;

      h2.title {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 5px;
      }

      h5 {
        color: #606060;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;

        margin-bottom: 18px;
      }

      p.text {
        color: #000;
        text-align: justify;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-bottom: 17px;
        a {
          text-decoration: underline;
        }
      }

      .form-control {
        max-width: 474px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 1024px) {
  .email-contact {
    .step-main {
      padding: 0 26px;
    }
  }
}

@media (max-width: 768px) {
  .email-contact {
    .step-main {
      min-height: calc(100vh - 61px - 210px);
      padding: 0 15px 30px;

      .step-form {
        padding: 20px 15px;
        border-radius: 12px;
        h2.title {
          font-size: 20px;

          margin-bottom: 5px;
        }

        h5 {
          font-size: 15px;

          margin-bottom: 13px;
        }

        .css-1fdsijx-ValueContainer {
          position: absolute;
          width: 100%;
        }
        .css-1hb7zxy-IndicatorsContainer {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .form-control {
          input {
            border-radius: 2px;
            height: 38px;
            padding: 0px 8px;

            font-size: 14px;

            &::placeholder {
              font-size: 14px;
            }
          }
        }

        p.text {
          font-size: 10px;
        }
        .prev-btn {
          width: 40px;
          height: 35px;

          img {
            max-width: 18px;
          }
        }
      }
    }
  }
}
