.FAQs {
  .box-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #3f3f3fdc;

    .container {
      height: 80vh;
      position: relative;

      .content-popup {
        position: relative;
        max-width: 1200px;
        padding: 41px 50px;
        height: 100%;

        border-radius: 8px;
        overflow-y: auto;
        overscroll-behavior: contain;

        background-color: #fff;
        h2.title {
          color: #000;
          text-align: center;
          font-family: Days One;
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin-bottom: 32px;
        }
        h4 {
          margin: 20px 0;
        }
        p.text {
          color: #000;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;

          margin-bottom: 13px;
        }
      }
      button.close {
        background-color: transparent;
        border: none;
        outline: none;

        background: rgba(255, 0, 0, 0.589);
        padding: 7px;
        border-radius: 50%;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -45px;
      }
    }
  }
}
@media (max-width: 1300px) {
  .FAQs {
    .box-popup {
      .container {
        height: 65vh;

        .content-popup {
          margin: 0 auto;
          padding: 30px 21px;

          h2.title {
            font-size: 22px;
            margin-bottom: 23px;
          }
          p.text {
            font-size: 16px;
          }
        }
        button.close {
          left: unset;
          bottom: unset;
          right: 15px;
          top: 15px;
        }
      }
    }
  }
}
